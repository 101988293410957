
.partners_section .swiper {
  width: 90%!important;
  /* height: 100%!important; */
}

.partners_section .swiper-slide {
  width: 100%!important;
  height: 300px!important;
  text-align: center;
  font-size: 18px;
  border-radius: 30px;
  overflow: hidden;
  /* background: rgba(205, 203, 203, 0.53); */


  
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media screen and (max-width:600px) {
  .partners_section  .swiper-slide {
    width: 20%!important;
  }
} */
  
.partners_section .swiper-slide img {
  width: 50%!important;
  height: unset;
}




  /* @media screen and (max-width:990px) {
    .partners_section  .swiper {
      width: 90%!important; 
      height: 50%!important;
    }
    
    .partners_section  .swiper-slide{
      height: 150px!important;
    }
  } */

  
  @media screen and (max-width:750px) {
    .partners_section  .swiper {
      width: 100%!important;
    }
    
    .partners_section .swiper-slide img{
      width: 80%!important;
    }

    /* .partners_section  .swiper-slide{
      height: 100px!important;
      margin-right: 15px!important;
    } */
  }

  /* @media screen and (max-width:750px) {
    
    .partners_section  .swiper-slide{
      margin-right: 5px!important;
    }
  } */
  