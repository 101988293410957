.slider_item{
    width: 100%;
    text-align: center;
    border-radius: 20px;
    padding: 30px;
    filter: blur(1px);
}

.main_card_shadow{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 10%,rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0.7) 90%, rgba(0, 0, 0, 0.8) 100%)
}

.my_slider_text{
    bottom: 0;
    left: 0;
}

.slider_an_text {
    margin: 0;
    text-align: center;
    font-size: 200px;
    overflow: hidden;
    line-height: 35%;
}

.my_slider .swiper-pagination-bullet{
    /* swiper-pagination-bullet */
    background: white!important;
}

.my_slider_text{
    
}
.slider_an_text span {
    display: block;
    animation: reveal 1.5s cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
}

@keyframes reveal {
    0% {
        transform: translate(0,100%);
    }
    100% {
        transform: translate(0,0);
    }
}
