.loader_transition{
  transition: 0.4s ease;
}

.single_card_title_span{
  opacity: 0;
  position: absolute;
  top: -30px;
  z-index: 40;
}

.navbar{
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6) 70%, rgba(0, 0, 0, 0.9));
  transition: ease-in .5s;
}

.my_card{
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0.8));
}

/* 
.navbar_hide{
  transform: translate(0px, -90px);
  transition: ease-in .5s;
} */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%!important;
  height: 100vh!important;
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}