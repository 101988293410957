.footer{
    background: url("../../assets/footerNewBack.jpg");
    background-size: cover;
    background-position: bottom center;
}

.footer_overlay{
    background-color: rgba(0, 8, 29, 0.9);
}

.network{
    background: #272B39;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: .2s;
    margin-right: 10px;
}

.network:hover{
    background: #244f98;
}