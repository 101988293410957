.product_list_item{
    font-size: 20px;
    list-style: none;
    position: relative;
}

.product_list_item::before{
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    background-color: #FF9001;
    top: 10px;
    left: -20px;
    z-index: 2;
    border-radius: 50%;
}

.product_table_item{
    font-size: 20px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.229);
    /* background: rgba(0, 0, 0, 0.264); */

}

.product_table_item p{
    padding: 6px;
}