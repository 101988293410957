.my_card_text{
    transform: translateY(-100px);
    /* position: relative; */
    animation-name: example;
    animation-duration: 2s;
    /* opacity: 0; */
    z-index: 40;
    color: #fff;
}

@keyframes example {
    100% {transform: translateY(-100px); opacity: 1;}
    0% {transform: translateY(50px); opacity: 0;}
}

.cards_shadow{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%,rgba(0, 0, 0, .3) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.6) 90%, rgba(0, 0, 0, 0.7) 100%);
}

/* .my_card_image,
.card_item_img
{
    transition: 1s ease-in-out;
} */

.my_card_image:hover .card_item_img{
    transform: scale(1.05, 1.05);
    transition: 1s ease-in-out;

}

.card_item_img{
    height: 100%;
    object-fit: cover;
    transition: 1s ease-in-out;
}

@media screen and (min-width:500px) {
    .card_item_img{
        width: 100%;
        height: 100%;
    }
}

@media screen and (min-width:680px) {
    .card_item_img{
        height: 100%;
        /* width: 100%; */
    }
}

/* @media screen and (max-width:500px) {
    .card_item_img{ */
        /* object-fit: contain; */
        /* width: 100%; */
    /* }
} */